import { render, staticRenderFns } from "./RequestForm1.vue?vue&type=template&id=18c462cc&scoped=true"
import script from "./RequestForm1.vue?vue&type=script&lang=js"
export * from "./RequestForm1.vue?vue&type=script&lang=js"
import style0 from "./RequestForm1.vue?vue&type=style&index=0&id=18c462cc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c462cc",
  null
  
)

export default component.exports